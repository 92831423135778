<template>
  <div>
    <div class="box">

      <inhead></inhead>

      <div class="navigation"></div>
      <div class="title_img"><img :src="title" alt="" width="100%" height="100%"></div>

      <div class="bottom_box">
        <div class="pc_bottom selectedBottom" @click="ss('pc')">电脑端</div>
        <div class="h5_bottom uncheckBottom" @click="ss('h5')">手机端</div>
      </div>

      <div class="pc_get_privacy">
        <div class="ps_bottom_box">
          <div>第一步打开STEAM ，点击自己的头像，找到《库存》选项点击。</div>
          <div class="content_box"><img :src="pc_1" alt="" width="100%" height="100%"></div>
        </div>
        <div class="ps_bottom_box">
          <div>第二步进入《库存》页面后,点击吃鸡库存,右键点击《复制页面URL》</div>
          <div class="content_box"><img :src="pc_2" alt="" width="100%" height="100%"></div>
        </div>

      </div>
      <div class="h5_get_privacy" style="display: none;">
        <div style="display: flex;">
          <div class="h5_bottom_box">
            <div style="font-size: 15px">第一步UU加速器加速steam令牌</div>
            <div class="content_box"><img :src="h5" alt="" width="166px"></div>
          </div>
          <div class="h5_bottom_box">
            <div style="font-size: 15px">第二部进入令牌点击头像-点击账户明细</div>
            <div class="content_box"><img :src="h5_1" alt="" width="166px"></div>
          </div>
          <div class="h5_bottom_box">
            <div style="font-size: 15px">第三步复制steam 17位ID 即可</div>
            <div class="content_box">
              <img :src="h5_2" alt="" width="166px">
            </div>
          </div>
        </div>
      </div>
    </div>

    <infoot></infoot>


  </div>
</template>

<script>
import  $ from 'jquery'
$('.h5_get_privacy').hide()

function pc_h5(v) {
  if (v === 'pc') {
    $('.pc_bottom').removeClass("uncheckBottom").addClass("selectedBottom")
    $('.h5_bottom').removeClass("selectedBottom").addClass("uncheckBottom")
    $('.h5_get_privacy').hide()
    $('.pc_get_privacy').show()
    if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)){
      $('.box').css('height', '100vh')
    }else{
      $('.box').css('height', '')
    }

  } else {
    $('.h5_bottom').removeClass("uncheckBottom").addClass("selectedBottom")
    $('.pc_bottom').removeClass("selectedBottom").addClass("uncheckBottom")
    $('.h5_get_privacy').show()
    $('.pc_get_privacy').hide()
    $('.box').css('height', '100vh')


  }
}
function pc() {
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    $('.box').css('height', '100vh')
    $('.h5_get_privacy').css('flex-direction','column').css('align-items','center')
  }
}






import inhead from '../inhead'
import infoot from '../infoot'

export default {
  components:{
    infoot,
    inhead
  },
  name: "HelpPhone",
  data(){
    return{
      title:require('@/assets/title3.png'),
      h5:require('@/assets/inventory_1.png'),
      h5_1:require('@/assets/inventory_2.png'),
      h5_2:require('@/assets/inventory_3.png'),
      pc_1:require('@/assets/content.png'),
      pc_2:require('@/assets/content2.png'),

      bg:require('@/assets/bg5.jpeg')

    }
  },
  methods:{
    ss(v){
      pc_h5(v)
    },
  },
  mounted() {
    if (location.href.indexOf("#reSurrection") == -1) {
      location.href = location.href + "#reSurrection";
      location.reload();


    }

    const metas = document.getElementsByTagName("meta");
    metas[4].content='width=device-width,initial-scale=0.4'

    pc()
  }

}
</script>

<style scoped>

.cccc{
  transition: all 0.5s;
  color: red;
}
.cccc:hover{
  cursor: pointer;
  transform: rotate(360deg) scale(1.2);
  color: #00b7ee;
}

.TitleImg{
  height: 55px;
  width: 155px;
  margin-top: 5px;
}


a {
  text-decoration: none
}


.navigation {
  width: 100%;
  height: 48px;
}


.ps_bottom_box {
  width: 908px;
  background: rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(255, 255, 266, 0.26);
  border-radius: 21px;
  margin: 25px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 26px;
  font-weight: 400;
  color: #FFFFFF;
  padding: 17px 0 39px 0;
  position: relative;
}

.title_img {
  width: 404px;
  height: auto;
  margin: 0 auto;
}

.content_box {
  margin-top: 26px;
  position: relative;
}

.selectedBottom {
  width: 153px;
  height: 41px;
  background: #0EA9F5;
  border-radius: 14px;
  font-size: 22px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 39px;
}

.uncheckBottom {
  width: 153px;
  height: 41px;
  border: 1px solid #0EA9F5;
  border-radius: 14px;
  font-size: 22px;
  font-weight: 400;
  color: #0EA9F5;
  line-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 39px;
}

.bottom_box {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 38px;
}

.h5_bottom_box {
  width: 278px;
  text-align: center;
  margin-right: 21px;
  background: rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(255, 255, 266, 0.26);
  padding: 7px 19px;
  margin-top: 25px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  position: relative;
  border-radius: 25px;
}

.h5_get_privacy {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 55px;
  /*flex-wrap: nowrap;*/
}

.pc_get_privacy {
  padding-bottom: 37px;
}

@media screen and (min-width: 769px ) {
  .box {
    width: 100%;
    /*/    height: auto;*/
    background:url("~@/assets/privacy_bg.png")  ;
  }



}

@media screen and (max-width: 768px ) {
  .box {
    width: 100%;
    height: 100vh;
    background: url('~@/assets/bg_innerImg.jpg');
    background-size: 100% 100%;
  }



}




</style>
